import React from 'react';

import './index.sass';
import ZENVIA_LOGO from '../../../assets/images/svg/logo.svg';

const Logo = () => (
	<div className="Logo" data-testid="component-logo">
		<img src={ZENVIA_LOGO} alt="logo" />
	</div>
);

export default Logo;
