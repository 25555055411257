import React, {
	useState, useEffect, useCallback, useMemo
} from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import './index.sass';
import { getCustomerFieldValue, parseCustomerName } from '../../../shared/utility';
import AvatarName from '../../Atoms/AvatarName';
import ActiveInteractions from '../../Organism/ActiveInteractions';
import Text from '../../Atoms/Text';
import Wrapper from '../../Atoms/Wrapper';

const messages = defineMessages({
	notRegistered: {
		id: 'agentContactItem.notRegistered',
		defaultMessage: 'Não identificado'
	}
});

const AgentContactItem = ({ customer, intl, showEditCustomer }) => {
	const { formatMessage } = intl;
	const { fields, customerKey } = customer;
	const [openEditCustomer, setOpenEditCustomer] = useState(true);

	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	useEffect(() => {
		setName(parseCustomerName(getCustomerFieldValue('main_identifier', fields) || formatMessage(messages.notRegistered)));
		const fieldPhone = getCustomerFieldValue('channel_phone', fields);
		setPhone(fieldPhone && fieldPhone.constructor === Array ? fieldPhone[0] : fieldPhone);
		const fieldEmail = getCustomerFieldValue('channel_email', fields);
		setEmail(fieldEmail && fieldEmail.constructor === Array ? fieldEmail[0] : fieldEmail);
	}, [fields, formatMessage]);

	const activeEditCustomer = useCallback(() => setOpenEditCustomer(true), []);

	const inactiveEditCustomer = useCallback(() => setOpenEditCustomer(false), []);

	const fullName = useMemo(() => getCustomerFieldValue('main_identifier', fields), [fields]);

	return (
		<Wrapper
			className="AgentContactItem"
			action={openEditCustomer ? () => showEditCustomer(customer) : () => {}}
		>
			<div className="AgentContactItem__avatar">
				<AvatarName name={name} />
			</div>
			<div className="AgentContactItem__info">
				<div className="AgentContactItem__header">
					<Text title={fullName || ''}>{name}</Text>
					<Text size="smaller">{email || phone}</Text>
				</div>
				<div
					onMouseOver={inactiveEditCustomer}
					onMouseOut={activeEditCustomer}
					onBlur={() => {}}
					onFocus={() => {}}
				>
					<ActiveInteractions fields={fields} customerKey={customerKey} emailType="activeEmail" isOnContacts />
				</div>
			</div>
		</Wrapper>
	);
};

AgentContactItem.propTypes = {
	customer: PropTypes.shape({
		fields: PropTypes.shape({
			main_identifier: PropTypes.string,
			channel_email: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
			channel_phone: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
		}),
		customerKey: PropTypes.string
	}).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired,
	showEditCustomer: PropTypes.func.isRequired
};

export default injectIntl(AgentContactItem);
