import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import './index.css';
import {
	getCustomerFieldValue,
	parseCustomerName
} from '../../../shared/utility';
import ImgSvg from '../../Atoms/ImgSvg';
import AvatarName from '../../Atoms/AvatarName';
import Wrapper from '../../Atoms/Wrapper';
import Text from '../../Atoms/Text';
import ChannelImage from '../../Atoms/ChannelImage';

const messages = defineMessages({
	notRegistered: {
		id: 'agentHistoryItem.notRegistered',
		defaultMessage: 'Não identificado'
	}
});

const AgentHistoryItem = ({
	interaction,
	click,
	currentInteractionHash,
	intl
}) => {
	const {
		customerInfo,
		newEndedTime,
		type,
		origin,
		interactionHash
	} = interaction;
	const { formatMessage } = intl;
	const { fields } = customerInfo;
	const originSvg = origin.toLowerCase() === 'outbound' ? 'sent' : 'incoming';

	const [name, setName] = useState('');
	const [content, setContent] = useState('');
	useEffect(() => {
		setName(parseCustomerName(getCustomerFieldValue('main_identifier', fields) || formatMessage(messages.notRegistered)));
		const fieldName = ['PHONE', 'WHATSAPP', 'TELEGRAM'].includes(type) ? 'channel_phone' : 'channel_email';
		const fieldContent = getCustomerFieldValue(fieldName, fields);
		setContent(fieldContent && fieldContent.constructor === Array ? fieldContent[0] : fieldContent);
	}, [type, fields, formatMessage]);

	const fullName = useMemo(() => getCustomerFieldValue('main_identifier', fields), [fields]);

	return (
		<Wrapper
			className={`AgentHistoryItem ${currentInteractionHash === interactionHash ? 'AgentHistoryItem--selected' : ''}`}
			action={() => click(interactionHash)}
		>
			<div className="AgentHistoryItem__avatar">
				<AvatarName name={name} />
				<ChannelImage name={type} />
			</div>
			<div className="AgentHistoryItem__info">
				<div className="AgentHistoryItem__header">
					<Text title={fullName || ''}>{name}</Text>
					<div className={`AgentHistoryItem__origin AgentHistoryItem__origin--${originSvg}`}>
						<ImgSvg name={originSvg} />
						<Text size="small">{newEndedTime}</Text>
					</div>
				</div>
				<Text>{content}</Text>
			</div>
		</Wrapper>
	);
};

AgentHistoryItem.propTypes = {
	interaction: PropTypes.shape({
		customerInfo: PropTypes.shape({
			fields: PropTypes.arrayOf(PropTypes.shape({
				name: PropTypes.string,
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(
					PropTypes.string
				)])
			}))
		}),
		newEndedTime: PropTypes.string,
		type: PropTypes.string,
		origin: PropTypes.string,
		interactionHash: PropTypes.string
	}).isRequired,
	click: PropTypes.func.isRequired,
	currentInteractionHash: PropTypes.string.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
};

export default injectIntl(AgentHistoryItem);
