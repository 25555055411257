export const ACTIVE_INTERACTION_STATES = ['TALKING', 'RINGING', 'FINISHING', 'ACCEPTING', 'ENDED', 'UNAVAILABLE', 'REPLYING'];
export const QUEUE_INTERACTION_STATES = ['NOT_ANSWER', 'QUEUED'];
export const INBOX_INTERACTION_STATES = ['POSTPONED', 'PENDING', 'ENDED'];
export const MISSED_INTERACTION_STATES = ['MISSED'];
export const ENDED_INTERACTION_STATES = ['ENDED', 'UNAVAILABLE', 'CANCELED', 'FINISHING'];

export const MESSAGE_CONTENT_TYPE = {
	TEXT: 'text',
	EMAIL: 'email',
	STATE: 'state',
	IMAGE: 'image',
	AUDIO: 'audio',
	VIDEO: 'video',
	DATE: 'date',
	PDF: 'pdf',
	APPLICATION: 'application',
	LOCATION: 'location',
	DOCUMENT: 'document'
};

export const CHARACTER_LIMIT_PER_CHANNEL = {
	WHATSAPP: 4026
};

export const MESSAGE_ORIGIN = {
	AGENT: 'agent',
	CLIENT: 'client',
	INT_AGENT: 'int_agent',
	INT_ADMIN: 'int_admin'
};

export const MESSAGE_STATUS = {
	NOT_ANSWER: 'NOT_ANSWER',
	QUEUED: 'QUEUED',
	RINGING: 'RINGING'
};

export const CHANNELS_WITH_REPLY = ['WHATSAPP'];
export const SOURCE_WITH_REPLY = ['kafka', 'uai_template'];

export const MIN_WIDTH_HIDE_CUSTOMER = 1400;
export const ZENVIA_FAVICON = 'https://zenvia-static.s3.amazonaws.com/favicon.ico';

export const ACCOUNT_FEATURES = {
	AUTOSTART_WEBCAM: 'autostart_webcam'
};

export const AUTH0_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*()/\-+=_^?])(?=.{8,})/;
